<template>
  <v-dialog
    ref="roleModal"
    v-model="isVisibleDialog"
    scrollable
    persistent
    max-width="800px"
  >
    <v-form
      ref="form"
      v-model="is_valid"
      lazy-validation
    >
      <v-card>
        <v-card-title class="pb-4">
          <span class="text-h3">{{ $t("role") }}</span>
        </v-card-title>
        <v-card-text class="pa-0 grey lighten-4">
          <v-container>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="role.type"
                      outlined
                      :disabled="
                        (!role.canEdit && role.canEdit !== undefined) ||
                          role.isRoleAssigned
                      "
                      dense
                      :items="productOptions"
                      item-value="id"
                      item-text="name"
                      :label="$t('user_type_*')"
                      :rules="[rules.required]"
                      @change="onRoleTypeChange"
                    />
                  </v-col>
                  <v-col
                    v-if="role.type == 'product'"
                    cols="6"
                  >
                    <v-select
                      v-model="role.name"
                      outlined
                      :disabled="
                        (!role.canEdit && role.canEdit !== undefined) ||
                          role.isRoleAssigned
                      "
                      dense
                      :items="nameOptions"
                      item-value="id"
                      item-text="name"
                      :label="$t('name_*')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    v-else
                    cols="6"
                  >
                    <v-text-field
                      v-model="role.name"
                      dense
                      outlined
                      :disabled="
                        (!role.canEdit && role.canEdit !== undefined) ||
                          role.isRoleAssigned
                      "
                      :label="$t('name_*')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    v-if="role.type == 'product'"
                    cols="6"
                  >
                    <v-select
                      v-model="role.product_tier_id"
                      outlined
                      :disabled="
                        (!role.canEdit && role.canEdit !== undefined) ||
                          role.isRoleAssigned
                      "
                      dense
                      :items="tiersOptions"
                      item-value="id"
                      item-text="product_tier_name"
                      :label="$t('product_tiers_*')"
                      :rules="[rules.required]"
                      @change="onTierChange"
                    />
                  </v-col>
                  <v-col
                    v-if="isOemType"
                    cols="6"
                  >
                    <v-autocomplete
                      v-model="role.tenant_id"
                      :disabled="role.isRoleAssigned"
                      dense
                      :items="oemClinetList"
                      item-value="id"
                      item-text="name"
                      :label="$t('select_tenant')"
                    />
                  </v-col>
                </v-row>

                <v-col cols="12">
                  {{ $t("permissions") }}
                </v-col>

                <v-container
                  v-for="(group, key) in permissions"
                  :key="key"
                >
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="module"
                            dense
                            outlined
                            hide-details
                            :ripple="false"
                            multiple
                            :label="key"
                            :value="key"
                            @change="updateRolePermissions(key, role)"
                          />
                        </v-col>

                        <v-col
                          v-for="permission in group"
                          :key="permission.id"
                          cols="3"
                        >
                          <v-checkbox
                            v-model="role.permission_ids"
                            dense
                            outlined
                            hide-details
                            :ripple="false"
                            :label="permission.name"
                            :value="permission.id"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="closeForm()"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!is_valid"
            :loading="is_loading_save"
            @click="saveForm(role)"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    items: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      module: [],
      is_valid: true,
      is_loading_save: false,
      productOptions: [
        // { id: "oem", name: "OEM" },
        { id: "orko", name: "ORKO" },
        { id: "product", name: "Product" },
      ],
      nameOptions: [
        { id: "admin", name: "Admin" },
        { id: "staff", name: "Staff" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      roles: "roles/listRoles",
      permissions: "permissions/listPermissions",
      oemClinetList: "oemclients/getOemsList",
      tiersOptions: "permissions/getTiersOptions",
    }),
    isOemType() {
      return this.role.type == "oem";
    },
    isVisibleDialog: {
      get() {
        return this.$store.state.roles.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("roles/IS_VISIBLE_DIALOG", value);
      },
    },
    role: {
      get() {
        return this.$store.state.roles.editedRole;
      },
      async set(value) {
        await this.$store.commit("roles/EDITED_ROLE", value);
      },
    },
  },
  async beforeMount() {
    if (!this.tiersOptions.length > 0) {
      await this.$store.dispatch("permissions/tiersOptions");
    }
    if (this.items.type == "product") {
      await this.$store.dispatch("permissions/tiersPermissionList", {
        product_tier_id: this.items.product_tier_id,
      });
    } else {
      this.$store.dispatch("permissions/list");
    }
  },
  methods: {
    closeForm() {
      this.$emit("close");
      this.$store.dispatch("roles/closeForm");
      this.$refs.form.reset();
      this.is_loading_save = false;
    },

    async saveForm(role) {
      if (this.$refs.form.validate()) {
        try {
          this.is_loading_save = true;
          if (role.id) {
            await this.$store.dispatch("roles/update", role);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("roles/add", role);
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          this.is_loading_save = false;
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.is_loading_save = false;
        }
        this.is_loading_save = false;
      }
    },

    updateRolePermissions(current_group, role) {
      const permission_ids = this.permissions[current_group].map((item) => {
        return item.id;
      });

      if (role.permission_ids !== undefined) {
        if (this.module.includes(current_group)) {
          permission_ids.forEach(function (id) {
            if (role.permission_ids.indexOf(id) == -1)
              role.permission_ids.push(id);
          });
        } else {
          permission_ids.forEach(function (id) {
            role.permission_ids = role.permission_ids.filter(function (item) {
              return item !== id;
            });
          });
        }
      }
    },
    onRoleTypeChange(val) {
      this.role.name = null;
      this.role.permission_ids = [];
      this.role.product_tier_id = null;
      if (val == "product") {
        this.$store.dispatch("permissions/tiersOptions");
      } else {
        this.$store.dispatch("permissions/list");
      }
    },
    async onTierChange(val) {
      this.role.permission_ids = [];
      if (val) {
        await this.$store.dispatch("permissions/tiersPermissionList", {
          product_tier_id: val,
        });
      } else {
        await this.$store.dispatch("permissions/list");
      }
    },
  },
};
</script>
